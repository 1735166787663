import { MarkerClusterer, defaultOnClusterClickHandler } from "@googlemaps/markerclusterer"
import { type ActionEvent, Controller } from "@hotwired/stimulus"

import { initCarousels } from "@/components/elm_search"
import Loader from "@/components/loader"
import { debounce } from "@/helpers/utils"

declare global {
  interface Window {
    mapItems: MapItem[]
  }
}

interface MapItem {
  elmCarouselFlags: Flags
  id: string
  name: string
  path: string
  position: LatLngLiteral
  premium: boolean
  price: string
  rating?: number
  ratingName?: string
}

type AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement & {
  // Avoid having to cast `Node` to `HTMLDivElement` everywhere
  content: HTMLDivElement
}
type GoogleMap = google.maps.Map
type LatLngBounds = google.maps.LatLngBounds
type LatLngLiteral = google.maps.LatLngLiteral
type MapsLibrary = google.maps.MapsLibrary
type MarkerLibrary = google.maps.MarkerLibrary

const ACTIVE_CLASS = "--active"
const ACTIVE_ZINDEX = 2000000 // Ensure markers appear above clusters
const FLOAT_MARKER_CONTENT_CSS_PROP = "--float-marker-content"
const HIDDEN = "hidden"
const HIGHLIGHTED_CLASS = "--highlighted"
const MAP_OPEN_CLASS = "--map-open"
const MARKER_STATE_CLASSES = Object.freeze([ACTIVE_CLASS, HIGHLIGHTED_CLASS] as const)
const RESULTS_COLLAPSED_CLASS = "--results-collapsed"
const SEARCH_BAR_HEIGHT_CSS_PROP = "--search-bar-height"
const SEARCH_BAR_ID = "elm-search-container"
const SESSION_STORAGE_KEYS = Object.freeze({
  MAP_OPEN: "search.map.map_open",
  RESULTS_OPEN: "search.map.results_open"
} as const)

export default class SearchMap extends Controller {
  static targets = ["floatingMarkerContent", "map"]
  declare readonly floatingMarkerContentTarget: HTMLElement
  declare readonly mapTarget: HTMLElement

  static values = {
    mapOpen: { type: Boolean, default: false },
    resultsOpen: { type: Boolean, default: false }
  }
  declare mapOpenValue: boolean
  declare resultsOpenValue: boolean

  private static AdvancedMarkerElement: typeof google.maps.marker.AdvancedMarkerElement

  private declare googleMap: GoogleMap
  private declare markerClusterer: MarkerClusterer
  private markers: AdvancedMarkerElement[] = []

  initialize(): void {
    this.mapOpenValue = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.MAP_OPEN) !== null
    this.resultsOpenValue =
      window.sessionStorage.getItem(SESSION_STORAGE_KEYS.RESULTS_OPEN) !== null
  }

  async mapOpenValueChanged(): Promise<void> {
    if (this.mapOpenValue) {
      if (!this.googleMap) {
        await this.initGoogleMap()
      }

      window.scrollTo(0, 0)
      this.setSearchBarHeightForCSS()
      this.mapTarget.removeAttribute(HIDDEN)
      document.body.classList.add(MAP_OPEN_CLASS)
      window.sessionStorage.setItem(SESSION_STORAGE_KEYS.MAP_OPEN, "")
    } else {
      this.deactivateMarkers()
      this.mapTarget.setAttribute(HIDDEN, "")
      document.body.classList.remove(MAP_OPEN_CLASS, RESULTS_COLLAPSED_CLASS)
      window.sessionStorage.removeItem(SESSION_STORAGE_KEYS.MAP_OPEN)
    }

    Loader.removeAll()
  }

  resultsOpenValueChanged(): void {
    // Toggle class on body instead of relevant element to prevent it getting removed by
    // Turbo stream response.
    document.body.classList.toggle(RESULTS_COLLAPSED_CLASS, this.resultsOpenValue)

    if (this.resultsOpenValue) {
      window.sessionStorage.setItem(SESSION_STORAGE_KEYS.RESULTS_OPEN, "")
    } else {
      window.sessionStorage.removeItem(SESSION_STORAGE_KEYS.RESULTS_OPEN)
    }
  }

  private static utils = {
    gcsImage: (file: string): string => {
      return `https://storage.googleapis.com/public.spabreaks.com/images/${file}`
    },

    toggleMarkerState: (
      marker: AdvancedMarkerElement,
      cssClass: (typeof MARKER_STATE_CLASSES)[number],
      force?: boolean
    ): void => {
      marker.content.classList.toggle(cssClass, force)

      // Marker can be both active and highlighted at the same time, so check both before
      // setting z-index.
      const isStateOn = MARKER_STATE_CLASSES.some((token) =>
        marker.content.classList.contains(token)
      )

      marker.zIndex = isStateOn ? ACTIVE_ZINDEX : 0
    }
  }

  deactivateMarkers(): void {
    this.markers = this.markers.map((marker) => this.toggleCardContent(marker))
  }

  hide(): void {
    this.mapOpenValue = false
    this.resultsOpenValue = false
  }

  onViewportChange(): void {
    this.deactivateMarkers()
    this.setSearchBarHeightForCSS()
  }

  async replaceMarkers(): Promise<void> {
    // This function is also called on `turbo-filters` frame load, which can obviously happen
    // before the map is ever initialized.
    if (!this.googleMap) {
      return
    }

    // Remove existing markers and clusters
    this.destroyMarkers()
    this.markerClusterer.clearMarkers()

    // Create new markers and clusters
    this.createMarkers(window.mapItems)
    this.markerClusterer.addMarkers(this.markers)

    // Set center and zoom
    if (this.markers.length) {
      const bounds = await this.boundsFromMarkers()
      this.googleMap.fitBounds(bounds)
    }
  }

  async show(): Promise<void> {
    this.mapOpenValue = true
  }

  toggleHighlightMarker({ params: { venueId } }: ActionEvent): void {
    const marker = this.markers.find(
      (marker) => marker.content.dataset.venueId === venueId.toString()
    )

    if (marker) {
      SearchMap.utils.toggleMarkerState(marker, HIGHLIGHTED_CLASS)
    }
  }

  toggleResultsList(): void {
    this.resultsOpenValue = !this.resultsOpenValue
  }

  private activateMarker(marker: AdvancedMarkerElement, item: MapItem): void {
    const cardContent = this.cardContent(item)

    this.toggleCardContent(marker, cardContent)
    this.googleMap.panTo(marker.position!)
    this.initCarousel(marker)
  }

  private activateMarkerOnClick(marker: AdvancedMarkerElement, item: MapItem): void {
    marker.addListener("click", () => {
      const isActive = marker.content.classList.contains(ACTIVE_CLASS)
      if (isActive) return

      // Deactivate any other active markers
      this.deactivateMarkers()

      // Activate clicked marker if it was previously deactivated
      this.activateMarker(marker, item)
    })
  }

  private appendHideControl(): void {
    const button = document.createElement("button")
    button.className = "ui-component-button variant:input search__map__hide-map"
    button.innerHTML = `<i class="far fa-times fa:before" aria-hidden="true"></i> Hide map`
    button.type = "button"

    button.addEventListener("click", this.hide.bind(this))
    this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(button)
  }

  private async boundsFromMarkers(): Promise<LatLngBounds> {
    const { LatLngBounds } = (await window.google.maps.importLibrary(
      "core"
    )) as google.maps.CoreLibrary
    const bounds = new LatLngBounds()

    this.markers.forEach((marker) => bounds.extend(marker.position!))

    return bounds
  }

  private cardContent(item: MapItem): string {
    return `
      <div class="marker-card ui-component-card spacing:compact width:sm layout:inline">
        <div data-img>
          <div class="elm-carousel" data-flags='${item.elmCarouselFlags}'></div>
          ${
            item.premium &&
            `<img alt="Elysium Collection" src="${SearchMap.utils.gcsImage("elysium-logo.png")}" width="150" height="50">`
          }
          <button data-action="search-map#deactivateMarkers:stop">
            <i class="fas fa-times-circle fa-2x" aria-hidden"true"></i>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="search-result__venue-info">
          <section>
            <header>
              <a href="${item.path}" target="_blank">
                ${item.name}
              </a>
              ${
                item.rating && item.ratingName
                  ? `<div>
                     <span class="rating">${item.rating}</span>
                     ${item.ratingName}
                   </div>`
                  : ""
              }
            </header>
          </section>
          <footer>
            <div class="ui-component-from-price variant:stacked">
              <span>from</span>
              <span class="price">${item.price}</span>
              <span>per person</span>
            </div>
          </footer>
        </div>
      </div>
    `
  }

  private createMarkerClusterer(): void {
    this.markerClusterer = new MarkerClusterer({
      map: this.googleMap,
      onClusterClick: (e, cluster, map): void => {
        defaultOnClusterClickHandler(e, cluster, map)

        // Default zoom is too much, back up one level
        map.setZoom(Math.max(1, map.getZoom()! - 1))
      }
    })
  }

  private createMarkers(items: MapItem[]): void {
    items.forEach((item) => {
      const marker = new SearchMap.AdvancedMarkerElement({
        content: this.markerView(item),
        map: this.googleMap,
        position: item.position,
        title: item.name
      }) as AdvancedMarkerElement

      this.activateMarkerOnClick(marker, item)
      this.markers.push(marker)
    })
  }

  private destroyMarkers(): void {
    this.markers = this.markers.flatMap((marker) => {
      marker.map = null
      return []
    })
  }

  private initCarousel(marker: AdvancedMarkerElement): void {
    const carouselContainer = this.shouldFloatMarkerContent
      ? this.floatingMarkerContentTarget
      : marker.content

    initCarousels(carouselContainer)
  }

  private async initGoogleMap(): Promise<void> {
    const { importLibrary } = window.google.maps
    const { Map } = (await importLibrary("maps")) as MapsLibrary
    const { AdvancedMarkerElement } = (await importLibrary("marker")) as MarkerLibrary
    const OPTIONS = {
      center: { lat: 53.5064506, lng: -2.3200198 }, // maps.google.co.uk default
      clickableIcons: false,
      fullscreenControl: false,
      gestureHandling: "greedy", // Move map with one finger instead of two on mobile
      mapId: "dcee0ac2d8a57685",
      mapTypeControl: false,
      maxZoom: 18,
      minZoom: 6,
      streetViewControl: false,
      zoom: 6,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP
      }
    }

    SearchMap.AdvancedMarkerElement = AdvancedMarkerElement
    this.googleMap = new Map(document.getElementById("js-google-map")!, OPTIONS)

    this.createMarkerClusterer()
    this.appendHideControl()
    void this.replaceMarkers()
    this.googleMap.addListener("click", this.deactivateMarkers.bind(this))
    this.googleMap.addListener("dragend", this.toggleMarkersInBounds.bind(this))
    this.googleMap.addListener("zoom_changed", debounce(this.onZoomChanged.bind(this), 500))
  }

  private markerView(item: MapItem): HTMLDivElement {
    const view = document.createElement("div")
    view.className = `search__map__marker${item.premium ? " theme:premium" : ""}`
    view.dataset.venueId = item.id
    view.innerHTML = this.pinContent(item)

    return view
  }

  private onZoomChanged(): void {
    this.deactivateMarkers()
    this.toggleMarkersInBounds()
  }

  private pinContent(item: MapItem): string {
    return `<div class="marker-pin"><div>${item.price}</div></div>`
  }

  private get searchBarElement(): HTMLElement {
    // Get search bar on the fly instead of on initialization to avoid potential race
    // conditions with Turbo.
    return document.getElementById(SEARCH_BAR_ID)!
  }

  private setSearchBarHeightForCSS(): void {
    window.requestAnimationFrame(() => {
      const { height } = this.searchBarElement.getBoundingClientRect() || {}

      if (height) {
        document.body.style.setProperty(SEARCH_BAR_HEIGHT_CSS_PROP, `${height}px`)
      }
    })
  }

  private get shouldFloatMarkerContent(): boolean {
    const shouldFloat = window
      .getComputedStyle(this.mapTarget)
      .getPropertyValue(FLOAT_MARKER_CONTENT_CSS_PROP)

    return shouldFloat === "true"
  }

  private toggleCardContent(
    marker: AdvancedMarkerElement,
    cardContent = ""
  ): AdvancedMarkerElement {
    const { content } = marker
    const pinContent = content.firstElementChild!.outerHTML

    if (this.shouldFloatMarkerContent) {
      this.floatingMarkerContentTarget.innerHTML = cardContent
    } else {
      // If `cardContent` present, it's added to the marker. Otherwise, existing card content is
      // removed by retaining only pin content from the marker.
      content.innerHTML = pinContent + cardContent
    }

    SearchMap.utils.toggleMarkerState(marker, ACTIVE_CLASS, !!cardContent)

    return marker
  }

  private toggleMarkersInBounds(): void {
    const bounds = this.googleMap.getBounds()

    // Remove all markers from clusterer
    this.markerClusterer.clearMarkers()

    // Add/remove map from markers based on current bounds
    this.markers = this.markers.map((marker) => {
      marker.map = bounds?.contains(marker.position!) ? this.googleMap : null
      return marker
    })

    // Add markers within bounds to clusterer
    this.markerClusterer.addMarkers(this.markers.filter((marker) => !!marker.map))
  }
}
